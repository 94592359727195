import React from 'react';
import Parse from 'parse';
import { IonCol, IonRow, IonGrid, IonText } from '@ionic/react';
import './KindmapContentExercise.css';

interface KindmapContentExerciseProps {
    content: Parse.Object
}

const KindmapContentExercise: React.FC<KindmapContentExerciseProps> = (props: KindmapContentExerciseProps) => {
    return (
        <IonGrid className="padding-materials">
            <IonRow className='ion-padding'>
                <IonCol size="12">
                    <IonText className="text-materials" >{props?.content?.attributes?.Text || 'Ups! Ocorreu um erro e não foi possível carregar o conteúdo.'}</IonText>
                </IonCol>
            </IonRow>

            <div className="spacer-div-materials-bottom-exercise"></div>
        </IonGrid>
    );

};

export default KindmapContentExercise;