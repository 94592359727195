import {
  IonRow,
  IonContent,
  IonLabel,
  IonIcon,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonCol,
  useIonViewWillEnter,
  useIonLoading,
  useIonRouter,
  IonToast,
} from "@ionic/react";
import React, { useState } from "react";
import KindmapCard from "../../components/KindmapCard";

// CSS
import "./Home.css";
/*import KindmapContentFilter from "../../components/KindmapContentFilter";*/

// ICONS
import UnCheckSvg from "../../components/Icons/un-check.svg";
import ExerciceSvg from "../../components/Icons/Exercice.svg";
import TextSvg from "../../components/Icons/Text.svg";
import VideoSvg from "../../components/Icons/Video.svg";
import AudioSvg from "../../components/Icons/Audio.svg";
import { alertCircleOutline } from "ionicons/icons";

const Home: React.FC = () => {
  const [state, setState] = useState({
    userData: null as Parse.Object[] | null,
    session: null as Parse.Object | null,
    meditation: null as Parse.Object | null,
    material: null as Parse.Object[] | null,
    contents: [] as Parse.Object[],
    filteredContents: [] as Parse.Object[]
  });
  const [showLoading, dismissLoading] = useIonLoading();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showToast, setShowToast] = useState(false);
  const [toastColor, setToastColor] = useState<"warning" | "medium">("warning");
  const router = useIonRouter();

  const getMeditationsFromSession = async (
    _meditation: Parse.Object
  ): Promise<void> => {
    try {
      //console.log("Meditation (of session): ", _meditation);
      setState((prevState) => ({ ...prevState, meditation: _meditation }));
    } catch (error) {
      console.error(
        "[ERROR (!)]: Erro ao obter os dados sobre a meditação da sessão atual. ",
        _meditation,
        error
      );
    }
  };

  const getContentsFromSession = async (
    _contents: Parse.Object[],
    selectedCategory?: string | null
  ): Promise<void> => {
    try {
      //console.log("Contents (of session):", _contents, " <---> ", selectedCategory);

      // If no category is selected, set all contents
      if (!selectedCategory) {
        setState((prevState) => ({
          ...prevState,
          contents: _contents,
          filteredContents: _contents
        }));
        return;
      }

      // Filter the contents based on the selected category
      const filteredContents = _contents.filter(
        (content) => content.get("Category") === selectedCategory
      );

      setState((prevState) => ({ ...prevState, filteredContents: filteredContents }));
    } catch (error) {
      console.error(
        `[ERROR]: Failed to fetch session contents for category '${selectedCategory}'.`,
        error
      );
      setToastColor("warning");
      setErrorMessage("Lamentamos, algo correu mal. Por favor, tente novamente mais tarde.");
      setShowToast(true);
    }
  };

  const categoryIcons: Record<string, string> = {
    text: TextSvg,
    audio: AudioSvg,
    exercise: ExerciceSvg,
    video: VideoSvg
  };

  const renderKindmapCard = (content: Parse.Object) => {
    return (
      <KindmapCard
        cardId={content.id}
        title={content.get("Description")}
        description={content.get("Text")}
        icon={categoryIcons[content.get("Category")] || UnCheckSvg}
        duration={content.get("Duration")}
        url={`/app/session/${state.session?.id}/materials/${content.id}`}
        lock={false}
      />
    );
  };

  useIonViewWillEnter(() => {
    const loadData = async () => {
      showLoading();
      try {
        const currentUser = Parse.User.current();
        if (!currentUser) {
          dismissLoading();
          setToastColor("warning");
          setErrorMessage("Não foi possível verificar a sua identidade. Por favor, inicie sessão novamente.");
          setShowToast(true);
          router.push("/login", "root", "replace");
          return;
        }

        const queryUserData = new Parse.Query("UserData");
        queryUserData.equalTo("userId", currentUser.id);
        queryUserData.equalTo("dataType", "CLASS");
        const userData = await queryUserData.find();

        if (!userData.length) {
          console.warn("No user data found.");
          return;
        }

        setState((prev) => ({ ...prev, userData }));

        const sessionId = userData[userData.length - 1].get("dataId");
        const sessionQuery = new Parse.Query("Classes");
        sessionQuery.equalTo("objectId", sessionId);
        const sessions = await sessionQuery.find();

        if (sessions.length === 0) {
          console.warn("No session found.");
          setToastColor("medium");
          setErrorMessage("Neste momento, não está inscrito em nenhum módulo. Aceda ao menu dos módulos para selecionar e iniciar um.");
          setShowToast(true);
          return;
        }

        const currentSession = sessions[0];
        setState((prev) => ({ ...prev, session: currentSession }));

        const meditationQuery = currentSession.get("Meditation")?.query();
        if (meditationQuery) {
          const meditations = await meditationQuery.find();
          if (meditations.length === 1) {
            getMeditationsFromSession(meditations[0]);
          }
        }

        const materialQuery = currentSession.get("Materials")?.query();
        if (materialQuery) {
          const materials = await materialQuery.find();
          if (materials.length > 0 && materials[0].get("Contents")) {
            setState((prev) => ({ ...prev, material: materials }));
            const contentsQuery = materials[0].get("Contents").query().ascending("order");
            const contents = await contentsQuery.find();
            getContentsFromSession(contents);
          }
        }
      } catch (error) {
        console.error("[ERROR]: Failed to fetch data", error);
        setToastColor("warning");
        setErrorMessage("Lamentamos, algo correu mal. Por favor, tente novamente mais tarde.");
        setShowToast(true);
        router.push("/login", "root", "replace");
      } finally {
        dismissLoading();
      }
    };

    loadData();
  });


  return (
    <IonPage>
      <IonHeader className="mobile-only">
        <IonToolbar>
          <IonTitle className="ion-title-sessions">Home</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Home</IonTitle>
          </IonToolbar>
        </IonHeader>

        <div className="top-spacer"></div>

        {/* Sessão attual
        <IonRow className="ion-padding">
          <IonCol className="content-session-completed-atual">
            <IonLabel className="ion-completed-label ion-padding">
              Meditação do módulo atual
            </IonLabel>
          </IonCol>
        </IonRow> */}

        <div className="content-container">
          <IonGrid>
            {/* meditações
            <IonRow className="content-container-row">
              {state.meditation ? (
                <ul className="timeline">
                  <li className="padding-card">
                    <div className="timeline-thumb timeline-icon">
                      <IonIcon icon={CheckSvg} />
                    </div>
                    <div className="timeline-item">
                      <IonGrid>
                        <IonCol size="11">
                          <KindmapCard
                            cardId={state.meditation.get("objectId")}
                            title={state.meditation.get("Title")}
                            description={state.meditation.get("Description")}
                            icon={MeditationSvg}
                            duration={state.meditation.get("Duration")}
                            url={`/app/session/${state.session?.id}/meditations/${state.meditation.id}`}
                            lock={false}
                          ></KindmapCard>
                        </IonCol>
                      </IonGrid>
                    </div>
                  </li>
                </ul>
              ) : (
                <IonGrid>
                    <p className="no-content">Não existem meditações para ouvir.</p>
                </IonGrid>
              )}
            </IonRow>*/}

            <IonRow className="ion-padding">
              <IonCol className="content-session-completed-atual">
                <IonLabel className="ion-completed-label ion-padding">
                  Conteúdo do módulo atual
                </IonLabel>
              </IonCol>
            </IonRow>

            {/* conteúdos */}
            <IonRow className="content-container-row">
              {state.filteredContents && state.filteredContents.length > 0 ? (
                <ul className="timeline">
                  {state.filteredContents.map((content, index) => (
                    <li key={index} className="padding-card">
                      <div className="timeline-thumb bg-color-blue timeline-icon">
                        <IonIcon icon={UnCheckSvg} />
                      </div>
                      <div className="timeline-item">
                        <IonGrid>
                          <IonCol size="11">
                            {renderKindmapCard(content)}
                          </IonCol>
                        </IonGrid>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <IonGrid>
                    <IonCol size="11">Não existe conteúdo para consultar.</IonCol>
                </IonGrid>
              )}
            </IonRow>

            <div className="spacer-home"></div>
          </IonGrid>
        </div>
      </IonContent>
      <div className="spacer-home">&nbsp;</div>
      <IonToast position="bottom" isOpen={showToast} icon={alertCircleOutline} message={errorMessage || undefined} duration={2000} color={toastColor} onDidDismiss={() => setShowToast(false)} />
    </IonPage>
  );
};

export default Home;
