import React, { useEffect } from "react";
import { useIonRouter, IonButton, IonContent, isPlatform, IonImg, IonItem, IonLabel, IonList } from "@ionic/react";
import "./Home.css";

import Logo from "../../components/Icons/logo_horizontal.svg";

const Home: React.FC = () => {
  const router = useIonRouter();

  useEffect(() => {
    if (isPlatform("mobile") && router.routeInfo.pathname !== "/login") {
      router.push("/login", "root", "replace");
    }
  });

  const handleLoginRedirect = async () => {
    await new Promise((resolve) => setTimeout(resolve, 50));
    router.push("/login", "forward");
  };

  return (
    <IonContent className="home__container no-select">
      <div className="home__content">
        <IonImg
          className="logo__home"
          src={Logo}
          alt="Logotipo oficial do projeto KindMap"
        ></IonImg>
        <h1 className="home__title home__title--text-large ion-text-center">KindMap: vamos desfrutar do caminho e cuidar do nosso coração!</h1>
        <p>&nbsp;</p>
        <p className="home__text">Com a KindMap irá...
          <IonList>
            <IonItem>
              <IonLabel>Aceder a informação sobre infertilidade e sobre o seu impacto psicológico</IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>Aceder a conteúdos que promovem o desenvolvimento de competências para lidar com os desafios da infertilidade</IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>Praticar exercícios experienciais e realizar meditações guiadas</IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>Ter momentos de reflexão acerca do seu percurso</IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>Guiar esta descoberta ao seu ritmo, onde e quando quiser</IonLabel>
            </IonItem>
          </IonList>
        </p>
        <IonButton className="home__button" onClick={handleLoginRedirect}>Entrar</IonButton>
      </div>
    </IonContent>
  );
};

export default Home;