import React from 'react';
import { IonCard, IonCol, IonGrid, IonRow } from '@ionic/react';
import KindmapAudioPlayer from './KindmapAudioPlayer';
import './KindmapContentAudio.css';

interface KindmapContentAudioProps {
    content: Parse.Object;
}

const KindmapContentAudio: React.FC<KindmapContentAudioProps> = ({ content }) => {
    const { Audio, Text } = content?.attributes || {};

    return (
        <IonGrid className="ion-padding content-audio-grid">
            <IonRow>
                <IonCol>
                    {Audio?.url() && (
                        <IonCard className="teste audio-card">
                            <KindmapAudioPlayer url={Audio.url()} title={Text ?? 'Conteúdo em áudio'} />
                        </IonCard>
                    )}
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <div className="spacer-div-materials-bottom-exercise"></div>
                </IonCol>
            </IonRow>
        </IonGrid>

    );
};

export default KindmapContentAudio;