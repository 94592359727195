import React, { useState, useEffect } from "react";
import { IonIcon, useIonRouter, IonTabBar, IonLabel, IonTabButton } from "@ionic/react";
import { useLocation } from "react-router-dom";
import "./PrivateMobileNavbar.css";

/* Custom Icons */
import HomeSvg from '../Icons/home.svg';
import SessoesSvg from '../Icons/sessions.svg';
import ProfileSvg from '../Icons/profile.svg';

/* Context */
import UIContext from "../../pages/private/hide-context";

const PrivateMobileNavbar: React.FC = () => {
    const { showTabs } = React.useContext(UIContext);
    const tabBarStyle = React.useMemo(() => (showTabs ? undefined : { display: "none" }), [showTabs]);

    const router = useIonRouter();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState<string>(location.pathname);

    useEffect(() => {
        setActiveTab(location.pathname);
    }, [location]);

    const navigateTo = (path: string) => {
        setActiveTab(path);
        router.push(path, "root");
    };

    return (
        <IonTabBar slot="bottom" className="tabar-mobile ion-hide-sm-up" style={tabBarStyle}>
            <IonTabButton
                className={`tabutton-mobile no-ripple ${activeTab === "/app/home" ? "active" : ""}`}
                tab="home"
                onClick={() => navigateTo("/app/home")}
            >
                <IonIcon icon={HomeSvg} />
                <IonLabel>Home</IonLabel>
            </IonTabButton>

            <IonTabButton
                className={`tabutton-mobile no-ripple ${activeTab === "/app/sessions" ? "active" : ""}`}
                tab="sessoes"
                onClick={() => navigateTo("/app/sessions")}
            >
                <IonIcon icon={SessoesSvg} />
                <IonLabel>Módulos</IonLabel>
            </IonTabButton>

            <IonTabButton
                className={`tabutton-mobile no-ripple ${activeTab === "/app/meditations" ? "active" : ""}`}
                tab="meditations"
                onClick={() => navigateTo("/app/meditations")}
            >
                <IonIcon icon={SessoesSvg} />
                <IonLabel>Meditações</IonLabel>
            </IonTabButton>

            <IonTabButton
                className={`tabutton-mobile no-ripple ${activeTab === "/app/profile" ? "active" : ""}`}
                tab="perfil"
                onClick={() => navigateTo("/app/profile")}
            >
                <IonIcon icon={ProfileSvg} />
                <IonLabel>Perfil</IonLabel>
            </IonTabButton>
        </IonTabBar>
    );
};

export default PrivateMobileNavbar;