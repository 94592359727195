import { IonRow, IonCol, IonButtons, IonButton, IonIcon, useIonLoading, IonGrid } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import DOMPurify from "dompurify";
import "./KindmapAudioPlayer.css";

/* ICONS */
import ButtonPlay from "./Icons/botao-play.svg";
import ButtonPause from "./Icons/botao-pause.svg";

interface KindmapAudioPlayerProps {
    url: string;
    title: string;
}

const KindmapAudioPlayer: React.FC<KindmapAudioPlayerProps> = ({ url, title }) => {
    const waveformRef = useRef<HTMLDivElement | null>(null);
    const wavesurfer = useRef<WaveSurfer | null>(null);
    const [playing, setPlaying] = useState(false);
    const [audioDuration, setAudioDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [showLoading, dismissLoading] = useIonLoading();

    // Sanitize the HTML content
    const rawTitle =
        title === undefined
            ? "A carregar o conteúdo..."
            : title || "Ups! Ocorreu um erro e não foi possível carregar o conteúdo.";
    const sanitizedTitle = DOMPurify.sanitize(rawTitle);

    useEffect(() => {
        const loadAudio = async () => {
            await showLoading();

            try {
                if (!url || !waveformRef.current) return;

                const abortController = new AbortController();
                const { signal } = abortController;

                if (!wavesurfer.current) {
                    wavesurfer.current = WaveSurfer.create({
                        container: waveformRef.current as HTMLElement,
                        waveColor: "#A7A6A6",
                        progressColor: "#F4CE79",
                        height: 75,
                        barWidth: 3,
                        barHeight: 1,
                        normalize: true,
                        cursorColor: "transparent",
                        autoplay: false,
                        backend: "WebAudio",
                        fetchParams: { signal },
                    });
                }

                wavesurfer.current.load(url);

                wavesurfer.current.on("ready", () => {
                    if (!signal.aborted) {
                        setAudioDuration(wavesurfer.current?.getDuration() || 0);
                    }
                });

                wavesurfer.current.on("audioprocess", () => {
                    if (!signal.aborted) {
                        setCurrentTime(wavesurfer.current?.getCurrentTime() || 0);
                    }
                });

                wavesurfer.current.on("finish", () => {
                    setPlaying(false);
                });

                return () => {
                    abortController.abort();
                    if (wavesurfer.current) {
                        wavesurfer.current.destroy();
                        wavesurfer.current = null;
                    }
                };
            } catch (error) {
                //console.error("[ERROR]: Failed to fetch audio file", error);
            } finally {
                await dismissLoading();
            }
        }

        loadAudio();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url]); // Runs only when the URL changes


    const formatTime = (time: number) => {
        const minutes = Math.floor((time % 3600) / 60).toString();
        const seconds = Math.floor(time % 60).toString().padStart(2, "0");
        return `${minutes}:${seconds}`;
    };

    const handlePlayPause = () => {
        if (wavesurfer.current) {
            wavesurfer.current.playPause();
            setPlaying((prev) => !prev);
        }
    };

    return (
        <IonGrid>
            <IonRow>
                <IonCol className="meditate-p">A reproduzir:</IonCol>
            </IonRow>
            <IonRow>
                <IonCol className="meditate-title">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: sanitizedTitle,
                        }}
                    ></div>
                </IonCol>
            </IonRow><br />

            <IonRow className="ion-text-center">
                <IonCol>
                    <span className="timer">{formatTime(currentTime)}</span>&nbsp;
                    <span className="timer">/</span>&nbsp;
                    <span className="timer">{formatTime(audioDuration)}</span>
                </IonCol>
            </IonRow>

            <div className="waveform-container">
                <div className="waveform-container-wave" ref={waveformRef}></div>
                <div className="waveform-container-controls">
                    <IonButtons>
                        <IonButton className="button-meditation" onClick={handlePlayPause}>
                            <IonIcon
                                className="ion-icon-control ion-icon-control-play"
                                src={playing ? ButtonPause : ButtonPlay}
                            ></IonIcon>
                        </IonButton>
                    </IonButtons>
                </div>
            </div>
        </IonGrid>
    );
};

export default KindmapAudioPlayer;