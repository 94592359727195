import React from "react";
import { IonContent, IonItem, IonLabel, IonList } from "@ionic/react";

const Investigacao: React.FC = () => {

  return (
    <IonContent className="home__container no-select">
      <div className="home__content">
        <IonList className="home__text">
          <IonItem>
            <IonLabel className="ion-text-justify">Galhardo, A., Cunha, M., & Pinto-Gouveia, J. (2013). <em>Mindfulness-based program for infertility: efficacy study</em>. Fertility and sterility, 100(4), 1059-1067. doi:  Available from: <a href="https://www.ncbi.nlm.nih.gov/pubmed/23809500" target="_blank" rel="noreferrer">https://www.ncbi.nlm.nih.gov/pubmed/23809500</a>.</IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel className="ion-text-justify">Galhardo, A., Cunha, M., & Pinto‐Gouveia, J. (2019). <em>A 7‐year follow‐up study of the Mindfulness‐Based Program for Infertility: Are there long‐term effects?</em>. Clinical Psychology & Psychotherapy, 26(4), 409-417. doi: 10.1002/cpp.2362. Available from: <a href="https://onlinelibrary.wiley.com/doi/abs/10.1002/cpp.2362" target="_blank" rel="noreferrer">https://onlinelibrary.wiley.com/doi/abs/10.1002/cpp.2362</a>.</IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel className="ion-text-justify">Galhardo, A., Carolino, N., Fonseca, F., Rôla, R., Pinto-Gouveia, J. & Cunha, M. (2021, September 24th - september 25th). <em>KindMap: o desenvolvimento de uma app para cultivar competências de mindfulness, compaixão e aceitação em pessoas com infertilidade</em> [Poster presentation]. XXXVII Jornadas de Estudos da Reprodução, Lisbon, Portugal.</IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel className="ion-text-justify">Carolino, N., Cunha, M., Pinto Gouveia, J. & Galhardo, A. (2022). <em>The causal framework underlying KindMap - An e-mental health intervention tool for improving well-being and mental health in people facing infertility</em>. Revista Psychologica, e066006, 28-29. doi: <a href="https://doi.org/10.14195/1647-8606_66_6" target="_blank" rel="noreferrer">https://doi.org/10.14195/1647-8606_66_6</a>.</IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel className="ion-text-justify">Galhardo, A., Carolino, N., Fonseca, F., Rola, R., Cunha, M., & Pinto Gouveia, J. (2022, June 14- June 19th). <em>KindMap: a mindfulness, ACT, and compassion-based self-guided web app targeting people with infertility</em> [Poster presentation]. ACBS World Conference 2022, San Francisco.</IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel className="ion-text-justify">Galhardo, A., Carolino, N., Fonseca, F., Rola, R., Cunha, M., & Pinto Gouveia, J. (2022, December 9th - December 12th). <em>KindMap: an e-mental health web app based on the Mindfulness Based Program for Infertility</em> [Oral presentation]. 18th Annual Conference Of IFS Fertivision 2022, San Francisco. New Delhi, India.</IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel className="ion-text-justify">Carolino, N., Galhardo, A., Pinto Gouveia, J.  & Cunha, M. (2023, March 30th - April 1st). <em>KindMap - An e-mental health intervention for people facing infertility: A feasibility study protocol</em> [Poster presentation]. IV International Congress of CINEICC, Coimbra, Portugal. doi: <a href="https://doi.org/10.14195/1647-8606_66_7" target="_blank" rel="noreferrer">https://doi.org/10.14195/1647-8606_66_7</a>.</IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel className="ion-text-justify">Galhardo, A., Carolino, N., Pinto-Gouveia, J. & Cunha, M. (2024, May 9th- May 11th). <em>Protocolo do estudo de viabilidade da KindMap: uma intervenção e-mental health para pessoas com infertilidade</em> [Poster presentation]. 9º Congresso Português de Medicina da Reprodução, Albufeira, Portugal.</IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel className="ion-text-justify">Carolino, N., Cunha, M., Pinto Gouveia, J. & Galhardo, A. (2024, September 3rd- September 6th). <em>KindMap – an e-mental health tool for people facing infertility</em> [Oral presentation]. 38th Annual Conference of the European Health Psychology Society, Cascais, Portugal.</IonLabel>
          </IonItem>
        </IonList>
      </div>
    </IonContent>
  );
};

export default Investigacao;