import React from "react";
import { IonContent, IonPage } from "@ionic/react";
import PrivateRoutes from "../../components/PrivateRoutes/PrivateRoutes";
import PrivateNavbar from "../../components/PrivateNavbar/PrivateNavbar";
import PrivateMobileNavbar from "../../components/PrivateNavbar/PrivateMobileNavbar";
import './Private.css';

const PrivateContainer: React.FC = () => {
  return (
    <IonPage id="private-container">
      {/* Menu Web */}
      <PrivateNavbar />

      <IonContent>
        <PrivateRoutes />
      </IonContent>

      {/* Menu Mobile */}
      <PrivateMobileNavbar />
    </IonPage>
  );
};

export default PrivateContainer;