import React from "react";
import { IonContent } from "@ionic/react";

const Contactos: React.FC = () => {
    return (
        <IonContent className="home__container no-select">
            <div className="home__content home__content--center">
                Se tiver alguma dúvida ou problema técnico, contacte-nos através do nosso e-mail <a href="mailto:kindmapstudy@gmail.com">kindmapstudy@gmail.com</a>
            </div>
        </IonContent>
    );
};

export default Contactos;