import React, { useEffect, useRef, useState } from "react";
import Parse from "parse";
import { IonContent, IonRow, IonCol, IonLabel, IonItem, IonInput, IonButton, IonToast, useIonRouter } from "@ionic/react";
import { alertCircleOutline } from 'ionicons/icons';
import '../../style/Public.css';

const Login: React.FC = () => {
    const router = useIonRouter();

    const [email, setEmail] = useState("");
    const emailRef = useRef("");

    const [password, setPassword] = useState("");
    const passwordRef = useRef("");

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [showToast, setShowToast] = useState(false);
    const [toastColor, setToastColor] = useState<"warning" | "medium">("warning");

    useEffect(() => {
        if (Parse.User.current()) {
            router.push("/app/home", "root", "replace");
        }
    });

    const handleEmailChange = (e: any) => {
        setEmail(e.detail.value!);
        emailRef.current = e.detail.value!;
    };

    const handlePasswordChange = (e: any) => {
        setPassword(e.detail.value!);
        passwordRef.current = e.detail.value!;
    };

    const doLogin = async (email: string, password: string): Promise<Parse.User | null> => {
        try {
            const user = await Parse.User.logIn(email, password);
            return user.isValid() ? user : null;
        } catch (error) {
            //console.error("Login failed:", error);
            return null;
        }
    };

    const doSignIn = async (): Promise<void> => {
        if (!emailRef.current || !passwordRef.current) {
            setToastColor("medium");
            setErrorMessage("Para continuar, preencha todos os campos.");
            setShowToast(true);
            return;
        }

        setLoading(true);
        try {
            const user = await doLogin(emailRef.current, passwordRef.current);
            if (user) {
                setLoading(false);
                router.push("/app/home", "root", "replace");
            } else {
                setToastColor("warning");
                setErrorMessage("O início de sessão falhou. Verifique as suas credenciais e tente novamente.");
                setShowToast(true);
            }
        } catch (error) {
            setToastColor("warning");
            setErrorMessage("Lamentamos, algo correu mal. Por favor, tente novamente mais tarde.");
            setShowToast(true);
            router.push("/", "root", "replace");
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        doSignIn();
    };

    return (
        <IonContent>
            <IonRow className="ion-padding">
                <IonCol className="ion-hide-md-down"></IonCol>
                <IonCol>
                    <IonLabel className="specialDiv">Bem-vind@ à <br />KindMap</IonLabel>
                    <p className="main__content">Insira as credenciais que lhe foram disponibilizadas pelos responsáveis do projeto para realizar a autenticação na aplicação.</p>
                    <form onSubmit={handleSubmit}>
                        <IonItem lines="none" className="login-style padding-form">
                            <IonLabel position="stacked">E-mail:</IonLabel>
                            <IonInput className="main__input" type="email" value={email} onIonChange={handleEmailChange} />
                        </IonItem>
                        <IonItem lines="none" className="padding-item">
                            <IonLabel position="stacked">Password:</IonLabel>
                            <IonInput className="main__input" type="password" value={password} onIonChange={handlePasswordChange} />
                        </IonItem>
                        <IonButton className="main__button--color" expand="block" type="submit" disabled={loading}>
                            {loading ? "Aguarde..." : "Login"}
                        </IonButton>
                    </form>
                </IonCol>
                <IonCol className="ion-hide-md-down"></IonCol>
            </IonRow>

            <IonToast position="bottom" isOpen={showToast} icon={alertCircleOutline} message={errorMessage || undefined} duration={2000} color={toastColor} onDidDismiss={() => setShowToast(false)} />
        </IonContent>
    );
};

export default Login;