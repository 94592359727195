import React from "react";
import { Redirect, Route } from "react-router";
import { IonRouterOutlet } from "@ionic/react";

// PAGES
import MaterialPage from "../../pages/private/Material";
import SessionsPage from "../../pages/private/Sessions";
import SessionPage from "../../pages/private/Session";
import ProfilePage from "../../pages/private/Profile";
import HomePage from "../../pages/private/Home";
import MeditationPage from "../../pages/private/Meditation";
import MeditationsPage from "../../pages/private/Meditations";

const PrivateRoutes: React.FC = () => {
    return (
        <IonRouterOutlet>
            <Route path="/app/home" component={HomePage} exact />
            <Route path="/app/meditations" component={MeditationsPage} exact />
            <Route path="/app/sessions" component={SessionsPage} exact />
            <Route path="/app/session/:id" component={SessionPage} exact />
            <Route path="/app/session/:idsession/meditations/:idmeditation" component={MeditationPage} exact />
            <Route path="/app/session/:idsession/materials/:idcontent" component={MaterialPage} exact />
            <Route path="/app/profile" component={ProfilePage} exact />
            <Redirect exact from="/app" to="/app/home" />
        </IonRouterOutlet>
    );
};

export default PrivateRoutes;