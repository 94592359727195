import React, { useState } from "react";
import { useParams } from 'react-router';
import { IonBackButton, IonHeader, IonToolbar, IonButtons, IonContent, IonPage, IonRow, IonCard, useIonViewWillEnter, useIonLoading, IonIcon, IonButton, useIonRouter } from '@ionic/react';
import { arrowForwardOutline, arrowBackOutline } from 'ionicons/icons';
import DOMPurify from "dompurify";
import "./Material.css";

/* componentes */
import KindmapContentVideo from "../../components/KindmapContentVideo";
import KindmapContentText from "../../components/KindmapContentText";
import KindmapContentAudio from "../../components/KindmapContentAudio";
import KindmapContentExercise from "../../components/KindmapContentExercise";

interface ParametersURL {
  idsession: string,
  idcontent: string
}

const Material: React.FC = () => {
  let urlParams = useParams<ParametersURL>();
  //console.log( "[Material.tsx] ", "URL: ", window.location.pathname," -> idsession: ", urlParams.idsession, "; idcontent:", urlParams.idcontent);

  const [content, setContent] = useState<Parse.Object>();
  const [prevContent, setPrevContent] = useState<string | null>(null);
  const [nextContent, setNextContent] = useState<string | null>(null);
  const [showLoading, dismissLoading] = useIonLoading();
  const router = useIonRouter();

  // Sanitize the HTML content
  const rawDescription =
    content?.attributes?.Description === undefined
      ? "A carregar o conteúdo..."
      : content?.attributes?.Description || "Ups! Ocorreu um erro e não foi possível carregar o conteúdo.";
  const sanitizedDescription = DOMPurify.sanitize(rawDescription);

  useIonViewWillEnter(() => {
    const loadMaterials = async () => {
      await showLoading();

      try {
        const currentUser = Parse.User.current();
        if (!currentUser) {
          router.push("/login", "root", "replace");
          dismissLoading();
          return;
        }

        // Fetch content and session simultaneously
        const [contentResult, sessionResult] = await Promise.all([
          new Parse.Query("Content").equalTo("objectId", urlParams.idcontent).find(),
          new Parse.Query("Classes").equalTo("objectId", urlParams.idsession).include("Materials").find()
        ]);

        setContent(contentResult[0]);
        const currentSession = sessionResult[0];

        const materialsField = currentSession.get("Materials");
        let materials: Parse.Object[];

        if (materialsField instanceof Parse.Object) {
          materials = [materialsField];
        } else if (materialsField?.query) {
          materials = await materialsField.query().ascending("order").find();
        } else if (Array.isArray(materialsField)) {
          materials = await Parse.Object.fetchAllIfNeeded(materialsField);
          materials.sort((a, b) => a.get("order") - b.get("order"));
        } else {
          materials = [];
        }

        if (materials.length === 0) {
          return;
        }

        const contentsField = materials[0].get("Contents");
        let contents: Parse.Object[] = [];

        if (contentsField?.query) {
          contents = await contentsField.query().ascending("order").find();
        } else if (Array.isArray(contentsField)) {
          contents = await Parse.Object.fetchAllIfNeeded(contentsField);
          contents.sort((a, b) => a.get("order") - b.get("order"));
        }

        if (contents.length > 0) {
          const currentIndex = contents.findIndex(content => content.id === urlParams.idcontent);
          setPrevContent(currentIndex > 0 ? contents[currentIndex - 1].id : null);
          setNextContent(currentIndex < contents.length - 1 ? contents[currentIndex + 1].id : null);
        }

      } catch (error) {
        console.error("[Material] ", error);
      } finally {
        dismissLoading();
      }
    };

    loadMaterials();
  });

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons className="space-button" slot="start">
            {prevContent ? (
              <IonButton
                fill="clear"
                className="color-back-icon"
                href={`/app/session/${urlParams?.idsession}/materials/${prevContent}`}
              >
                <IonIcon slot="icon-only" ios={arrowBackOutline} md={arrowBackOutline} />
              </IonButton>
            ) : (
              <IonBackButton
                className="color-back-icon"
                text=""
                defaultHref={`/app/session/${urlParams?.idsession}`}
              />
            )}
          </IonButtons>
          {nextContent && (
            <IonButtons className="space-button space-button--right" slot="end">
              <IonButton
                fill="clear"
                className="color-back-icon"
                href={`/app/session/${urlParams?.idsession}/materials/${nextContent}`}
              >
                <IonIcon slot="icon-only" ios={arrowForwardOutline} md={arrowForwardOutline} />
              </IonButton>
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>

      <IonRow className="first-title-materials">
        {/*<IonLabel>{content?.attributes?.Description}</IonLabel>*/}
        <div
          dangerouslySetInnerHTML={{
            __html: sanitizedDescription,
          }}
        ></div>
      </IonRow>

      <IonContent scrollY={false}>
        <IonCard className="background-material">
          <IonContent className="background-color-content" scrollY={true}>
            {content?.attributes?.Category === "video" && <KindmapContentVideo content={content as any} />}
            {content?.attributes?.Category === "text" && <KindmapContentText content={content as any} />}
            {content?.attributes?.Category === "audio" && <KindmapContentAudio content={content as any} />}
            {content?.attributes?.Category === "exercise" && <KindmapContentExercise content={content as any} />}
          </IonContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default Material;
