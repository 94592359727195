import { IonContent, IonGrid, IonHeader, IonPage, IonTitle, IonToolbar, useIonLoading, useIonRouter, useIonViewWillEnter } from '@ionic/react';
import Parse from 'parse';
import React, { useState } from 'react';
import KindmapCard from '../../components/KindmapCard';
import './Sessions.css';

const Sessions: React.FC = () => {
  const [sessions, setSessions] = useState<Parse.Object[]>([]);
  const [showLoading, dismissLoading] = useIonLoading();
  const router = useIonRouter();

  useIonViewWillEnter(() => {
    const loadSessions = async () => {
      await showLoading();

      try {
        const currentUser = Parse.User.current();

        if (!currentUser) {
          dismissLoading();
          router.push("/login", "root", "replace");
          return;
        }

        const query = new Parse.Query("Classes");
        const results = await query.find();

        // Check if the Parent field exists and has a query method
        const parents = await Promise.all(
          results.map((classe: Parse.Object) => {
            const parent = classe.get("Parent");
            if (parent && typeof parent.query === "function") {
              return parent.query().find();
            } else {
              return Promise.resolve([]);
            }
          })
        );

        results.forEach((classe: Parse.Object, index: number) => {
          classe.set("Parent", parents[index].length > 0 ? parents[index][0] : null);
        });

        const userDataPromises = results.map((classe: Parse.Object) => {
          const parent = classe.get("Parent");
          if (!parent) return Promise.resolve([]);

          const queryUserData = new Parse.Query("UserData");
          queryUserData.equalTo("userId", currentUser.id);
          queryUserData.equalTo("dataId", parent.id);
          queryUserData.equalTo("dataType", "CLASS");

          return queryUserData.find();
        });

        const userDataResults = await Promise.all(userDataPromises);

        // Update session data with UserData
        results.forEach((session: Parse.Object, index: number) => {
          const parent = session.get("Parent");
          if (parent) {
            parent.set("userData", userDataResults[index].length > 0 ? userDataResults[index][0] : null);
          }
        });

        setSessions(results);
      } catch (error) {
        //console.error("[ERROR]: Failed to fetch sessions", error);
      } finally {
        dismissLoading();
      }
    };

    loadSessions();
  });


  const isLock = (session: Parse.Object): boolean => {
    const parent = session.get("Parent");
    if (!parent) return false;

    const userData = parent.get("userData");
    return !userData || !userData.get("completed");
  };

  return (
    <IonPage>
      <IonHeader className="mobile-only">
        <IonToolbar>
          <IonTitle className="ion-title-sessions">Lista de Módulos</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid className="ion-padding-without-bottom">
          <div className="ion-padding-card"></div>
          {sessions.map((session) => {
            const { Title, Description, Icon, Duration } = session.attributes;
            return (
              <KindmapCard
                key={session.id}
                cardId={session.id}
                title={Title}
                description={Description}
                icon={Icon?.url()}
                duration={Duration}
                url={`/app/session/${session.id}`}
                lock={isLock(session)}
              />
            );
          })}
        </IonGrid>
        <div className="spacer-sessions"></div>
      </IonContent>
    </IonPage>
  );
};

export default Sessions;