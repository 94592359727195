import React from "react";
import './KindmapVideoPlayer.css';

interface KindmapVideoPlayerProps {
  url: string;
  title?: string;
}

const KindmapVideoPlayer: React.FC<KindmapVideoPlayerProps> = ({ url, title }) => {

  const isValidUrl = (url: string) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  return (
    <div className="player-wrapper">
      {isValidUrl(url) ? (
        <iframe
          src={url}
          title={title || "Leitor de Vídeo"}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          sandbox="allow-same-origin allow-scripts allow-popups allow-presentation"
          allowFullScreen
          loading="lazy"
          aria-label={title || "Leitor de vídeo"}
        ></iframe>
      ) : (
        <p>Ups! Existiu um erro ao carregar o vídeo. Volte a tentar mais tarde.</p>
      )}
    </div>
  );
};

export default KindmapVideoPlayer;
