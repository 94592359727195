import React from "react";
import { IonContent, IonItem, IonLabel, IonList } from "@ionic/react";

const Servicos: React.FC = () => {
    return (
      <IonContent className="home__container no-select">
        <div className="home__content">
          <p className="home__text ion-text-justify">Pode aqui aceder a algumas instituições/associações/projetos que apoiam e/ou oferecerem apoio psicológico e acompanhamento neste percurso.</p>
          <p className="home__text ion-text-justify"><strong>Nota</strong>: A KindMap não se responsabiliza pelos conteúdos destes websites.</p>
          <br />
          <p className="home__text"><strong>Portugal</strong>:</p>
          <IonList className="home__text">
            <IonItem>
              <IonLabel>APFertilidade - <a href="https://apfertilidade.org" target="_blank" rel="noreferrer">https://apfertilidade.org</a></IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>APM - <a href="http://apmindfulness.com" target="_blank" rel="noreferrer">http://apmindfulness.com</a></IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>Saúde Mental - <a href="https://saudemental.pt" target="_blank" rel="noreferrer">https://saudemental.pt</a></IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>Portal da Saúde Mental - <a href="https://www.portaldasaudemental.pt" target="_blank" rel="noreferrer">https://www.portaldasaudemental.pt</a></IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>Aconselhamento Psicológico no SNS 24 - <a href="https://www.sns24.gov.pt/guia/aconselhamento-psicologico-no-sns-24/" target="_blank" rel="noreferrer">https://www.sns24.gov.pt/guia/aconselhamento-psicologico-no-sns-24/</a></IonLabel>
            </IonItem>
          </IonList>
          <br />
          <p className="home__text"><strong>Reino Unido</strong>:</p>
          <IonList className="home__text">
            <IonItem>
              <IonLabel>Fertility Network UK - <a href="https://fertilitynetworkuk.org" target="_blank" rel="noreferrer">https://fertilitynetworkuk.org</a></IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>British Infertility Counselling Association (BICA) - <a href="https://www.bica.net" target="_blank" rel="noreferrer">https://www.bica.net</a></IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>NHS – Mental Health Helplines - <a href="https://www.nhs.uk/nhs-services/mental-health-services/" target="_blank" rel="noreferrer">https://www.nhs.uk/nhs-services/mental-health-services/</a></IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>NHS – How to access mental health support - <a href="https://www.nhs.uk/mental-health/social-care-and-your-rights/how-to-access-mental-health-services/" target="_blank" rel="noreferrer">https://www.nhs.uk/mental-health/social-care-and-your-rights/how-to-access-mental-health-services/</a></IonLabel>
            </IonItem>
          </IonList>
        </div>
      </IonContent>
    );
};

export default Servicos;