import React, { useEffect, useState } from "react";
import { IonToolbar, IonRouterLink, IonIcon, IonButtons, IonButton, IonTitle, useIonRouter } from "@ionic/react";
import { useLocation } from "react-router-dom";
import './PrivateNavbar.css';

// LOGO
import Logo from "../../components/Icons/logo_horizontal.svg";

const PrivateNavbar: React.FC = () => {
    const router = useIonRouter();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState<string>(location.pathname);

    useEffect(() => {
        setActiveTab(location.pathname);
    }, [location]);

    const navigateTo = (path: string) => {
        setActiveTab(path);
        router.push(path, "root");
    };

    return (
        <IonToolbar slot="top" className="ion-hide-sm-down">
            <IonRouterLink routerLink="/app/home">
                <IonIcon className="logo" icon={Logo} />
            </IonRouterLink>
            <IonButtons slot="end">
                <IonButton
                    className={`no-ripple ${activeTab === "/app/home" ? "active" : ""}`}
                    onClick={() => navigateTo("/app/home")}
                >
                    <IonTitle className="green-hover">Home</IonTitle>
                </IonButton>
                <IonButton
                    className={`no-ripple ${activeTab === "/app/sessions" ? "active" : ""}`}
                    onClick={() => navigateTo("/app/sessions")}
                >
                    <IonTitle className="green-hover">Módulos</IonTitle>
                </IonButton>
                <IonButton
                    className={`no-ripple ${activeTab === "/app/meditations" ? "active" : ""}`}
                    onClick={() => navigateTo("/app/meditations")}
                >
                    <IonTitle className="green-hover">Meditações</IonTitle>
                </IonButton>
                <IonButton
                    className={`no-ripple ${activeTab === "/app/profile" ? "active" : ""}`}
                    onClick={() => navigateTo("/app/profile")}
                >
                    <IonTitle className="green-hover">Perfil</IonTitle>
                </IonButton>
            </IonButtons>
        </IonToolbar>
    );
};

export default PrivateNavbar;