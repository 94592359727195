import React from "react";
import { IonCol, IonContent, IonGrid, IonImg, IonRow } from "@ionic/react";
import "./Home.css";

const Team: React.FC = () => {

    return (
        <IonContent id="scrollbar1" className="home__container no-select">
            <div className="home__content home__content--small-width">
                <p className="home__text ion-text-justify">A <strong>KindMap</strong> foi desenvolvida por uma equipa de investigadores do Instituto Superior Miguel Torga e do CINEICC da Universidade de Coimbra, Faculdade de Psicologia e Ciências da Educação, com o apoio da APFertilidade e da Associação Portuguesa para o Mindfulness. O desenvolvimento da aplicação web <strong>KindMap</strong> foi financiada pela APFertilidade. O estudo de viabilidade da <strong>KindMap</strong> é financiado através de Bolsa de Doutoramento atribuída pela Fundação para a Ciência e Tecnologia (FCT).</p>

                <IonGrid>
                    <IonRow className="row--align__bottom">
                        <IonCol size="6" sizeMd="4" sizeLg="2.4">
                            <IonImg className="logo__team" src="assets/images/logo_ismt.png" alt="Instituto Superior Miguel Torga" />
                        </IonCol>
                        <IonCol size="6" sizeMd="4" sizeLg="2.4">
                            <IonImg className="logo__team" src="assets/images/logo_CINEICC.png" alt="Centro de Investigação em Neuropsicologia e Intervenção Cognitivo-Comportamental da Universidade de Coimbra" />
                        </IonCol>
                        <IonCol size="6" sizeMd="4" sizeLg="2.4">
                            <IonImg className="logo__team" src="assets/images/logo_uc.png" alt="Universidade de Coimbra" />
                        </IonCol>
                        <IonCol size="6" sizeMd="4" sizeLg="2.4">
                            <IonImg className="logo__team" src="assets/images/logo_apf_96.png" alt="Associação Portuguesa de Fertilidade" />
                        </IonCol>
                        <IonCol size="6" sizeMd="4" sizeLg="2.4">
                            <IonImg className="logo__team" src="assets/images/logo_apm.jpeg" alt="Associação Portuguesa para o Mindfulness" />
                        </IonCol>
                    </IonRow>
                </IonGrid>

                <br /><p className="home__title home__title--text-large home__title--strong">Naír Carolino</p> (foto)
                <p className="home__text ion-text-justify">Psicóloga Clínica certificada pela Ordem dos Psicólogos Portugueses
                    Assistente no Instituto Superior Miguel Torga
                    Doutoranda em Psicologia Clínica na Faculdade de Psicologia e Ciências da Educação da Universidade de Coimbra
                    Membro da Associação Portuguesa para o Mindfulness (APM)</p>

                <br /><p className="home__title home__title--text-large home__title--strong">Ana Galhardo</p> (foto)
                <p className="home__text ion-text-justify">Psicóloga Clínica, com especialidade em Psicologia Clínica e da Saúde e especialidade avançada em Psicoterapia, certificada pela Ordem dos Psicólogos Portugueses
                    Professora Auxiliar no Instituto Superior Miguel Torga
                    Doutorada em Psicologia Clínica pela Faculdade de Psicologia e Ciências da Educação da Universidade de Coimbra
                    Investigadora do Centro de Investigação em Neuropsicologia e Intervenção Cognitivo-Comportamental (CINEICC) da Faculdade de Psicologia e de Ciências da Educação da Universidade de Coimbra
                    Presidente da Assembleia Geral e membro da Associação Portuguesa para o Mindfulness (APM)
                    Membro do Conselho Editorial  da Human Reproduction (ESHRE)
                    Membro do Grupo de Trabalho para as questões de Saúde Reprodutiva e Procriação Medicamente Assistida da Ordem dos Psicólogos Portugueses
                    Coordenadora da Rede de Apoio Psicológico da APFertilidade</p>

                <br /><p className="home__title home__title--text-large home__title--strong">Marina Cunha</p> (foto)
                <p className="home__text ion-text-justify">Psicóloga Clínica, com especialidade em Psicologia Clínica e da Saúde e especialidade avançada em Psicoterapia, certificada pela Ordem dos Psicólogos Portugueses
                    Professora Auxiliar com Agregação no Instituto Superior Miguel Torga
                    Doutorada em Psicologia Clínica pela Faculdade de Psicologia e Ciências da Educação da Universidade de Coimbra
                    Invetsigadora do Centro de Investigação em Neuropsicologia e Intervenção Cognitivo-Comportamental (CINEICC) da Faculdade de Psicologia e de Ciências da Educação da Universidade de Coimbra
                    Vogal da direção e membro da Associação Portuguesa para o Mindfulness (APM)</p>

                <br /><p className="home__title home__title--text-large home__title--strong">José Pinto-Gouveia</p> (foto)
                <p className="home__text ion-text-justify">Médico Psiquiatra certificado pela Ordem dos Médicos
                    Professor Catedrático Jubilado da Faculdade de Psicologia e de Ciências da Educação da Universidade de Coimbra
                    Ex-Coordenador do Centro de Investigação em Neuropsicologia e Intervenção Cognitivo-Comportamental (CINEICC) da Faculdade de Psicologia e de Ciências da Educação da Universidade de Coimbra
                    Presidente da direção e membro da Associação Portuguesa para o Mindfulness (APM)</p>

                <br /><p className="home__title home__title--text-large home__title--strong">Sofia Gameiro</p> (foto)
                <p className="home__text ion-text-justify">Psicóloga Clínica certificada
                    Professora Sénior Escola de Psicologia da Universidade de Cardiff
                    Doutorada em Psicologia Clínica pela Faculdade de Psicologia e Ciências da Educação da Universidade de Coimbra
                    Coordenadora e Conselheira do grupo de interesse de Psicologia da European Society of Human Reproduction and Embryology (ESHRE)
                    Membro do Conselho Editorial  da Human Reproduction (ESHRE) e da Fertility and Sterility (ASRM)</p>

                <br /><p className="home__title home__title--text-large home__title--strong">Joana Freire</p> (foto)
                <p className="home__text ion-text-justify">Diretora Executiva da APFertilidade
                    Autora do livro “A Viagem que não escolhemos – Histórias sobre a Infertilidade”</p>

                <br /><p className="home__title home__title--text-large home__title--strong">Frederico Fonseca</p> (foto + nota curricular)
                <p className="home__text ion-text-justify">Assistente no Instituto Superior Miguel Torga
                    Coordenador executivo do 1º ciclo em Multimédia
                    Doutorando em Ciências da Comunicação (área da literacia digital, discursos mediáticos, infoexclusão, envelhecimento ativo e jogos sérios)
                    Licenciado em Multimédia
                    Investigador no Instituto Politécnico de Viseu Centro de Investigação em Serviços Digitais (CISeD)</p>

                <br /><p className="home__title home__title--text-large home__title--strong">Rute Rôla</p> (foto + nota curricular)
                <p className="home__text">Licenciada em Multimédia</p>
            </div>
        </IonContent>
    );
};

export default Team;