import React from 'react';
import { IonCol, IonRow, IonGrid } from '@ionic/react';
import KindmapVideoPlayer from './KindmapVideoPlayer';
import DOMPurify from "dompurify";
import './KindmapContentVideo.css';

interface KindmapContentVideoProps {
    content: {
        attributes: {
            Text: string;
            Url: string;
        };
    };
}

const KindmapContentVideo: React.FC<KindmapContentVideoProps> = ({ content }) => {
    const { Text, Url } = content.attributes;
    const rawText =
        Text === undefined
            ? "A carregar o conteúdo..."
            : Text || "Ups! Ocorreu um erro e não foi possível carregar o conteúdo.";
    const sanitizedText = DOMPurify.sanitize(rawText);

    return (
        <IonGrid className="padding-materials">
            <IonRow className='ion-padding'>
                <IonCol>
                    {/*<IonText className="text-materials">
                        {Text || 'Ups! Ocorreu um erro e não foi possível carregar o vídeo.'}
                    </IonText>*/}
                    <div
                        className="text-materials"
                        dangerouslySetInnerHTML={{
                            __html: sanitizedText,
                        }}
                    ></div>
                </IonCol>
            </IonRow>

            <IonRow className="ion-padding video-row">
                <IonCol>
                    <KindmapVideoPlayer url={Url} />
                </IonCol>
            </IonRow>

            <div className="spacer-div-materials-bottom-exercise"></div>
        </IonGrid>
    );
};

export default React.memo(KindmapContentVideo);
