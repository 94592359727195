import React from 'react';
import { IonCol, IonRow } from '@ionic/react';
import KindmapCard from './KindmapCard';
import './KindmapMaterials.css';

interface KindmapMaterialProps {
    sessionId: string
    material: Parse.Object
    contents: Parse.Object[]
}

const KindmapMaterials: React.FC<KindmapMaterialProps> = (props: KindmapMaterialProps) => {
    return (
        <div className="kindmap-materials">
            {/*<IonRow className="ion-padding-row-title">
                <IonLabel className="ion-title-session">{props.material?.attributes?.Title}</IonLabel>
            </IonRow>
            <IonRow className="ion-padding-row">
                <IonLabel className="ion-title-session">{props.material?.attributes?.Title}</IonLabel>&nbsp;-&nbsp;
                <IonText className="ion-text-session">{props.material?.attributes?.Description}</IonText>
            </IonRow>*/}
            <IonRow className="ion-text-start">
                <IonCol>
                    {
                        props.contents && props.contents.map((content: Parse.Object) => (
                            <KindmapCard
                                key={content.id}
                                cardId={content.id}
                                title={content.attributes?.Description}
                                description={content.attributes?.Text}
                                icon={content.attributes?.Icon?.url()}
                                duration={content.attributes?.Duration}
                                url={`/app/session/${props?.sessionId}/materials/${content.id}`}
                                lock={false}>
                            </KindmapCard>
                        ))
                    }
                </IonCol>
            </IonRow>

            <div className="spacer-div-materials-bottom-exercise"></div>
        </div>
    )
};

export default KindmapMaterials;