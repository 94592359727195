import React, { ReactNode } from "react";
import {
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonRow,
  useIonRouter,
} from "@ionic/react";
import DOMPurify from "dompurify";
import "./KindmapCard.css";

interface KindmapCardProps {
  children?: ReactNode;
  cardId: string;
  title: string;
  description: string;
  icon: string;
  duration: string;
  url: string;
  lock: boolean;
}

const KindmapCard: React.FC<KindmapCardProps> = ({
  cardId,
  title,
  description,
  icon,
  duration,
  url,
  lock,
}) => {
  const router = useIonRouter();

  // Sanitize the HTML content
  const rawTitle =
    title === undefined
      ? "A carregar o conteúdo..."
      : title || "Ups! Ocorreu um erro e não foi possível carregar o conteúdo.";
  const sanitizedTitle = DOMPurify.sanitize(rawTitle);
  const sanitizedTitleWithoutP = sanitizedTitle.replace(/^<p>/, '').replace(/<\/p>$/, '');

  // Sanitize the HTML content
  const rawText =
    description === undefined
      ? "A carregar o conteúdo..."
      : description || "Ups! Ocorreu um erro e não foi possível carregar o conteúdo.";
  const sanitizedContent = DOMPurify.sanitize(rawText);

  const openUrl = () => {
    if (!lock) {
      router.push(url, "forward");
    }
  };

  // Function to limit description text to 130 characters
  const getShortDescription = (text: string) => {
    const textOnly = text.replace(/<[^>]*>/g, '');
    return textOnly.length > 100 ? `${textOnly.slice(0, 130)}...` : textOnly;
  };

  return (
    <IonGrid className="no-padding-grid">
      <IonCard
        className={`ion-card ${lock ? "overlay-card" : ""}`}
        onClick={openUrl}
      >
        <IonCardContent className="ion-card-content">
          <IonRow>
            {icon && (
              <IonCol className="ion-text-center" size-xs="2" size-lg="0.4">
                <img
                  className={`ion-icon ${lock ? "overlay-color" : ""}`}
                  src={icon}
                  alt="Logo do conteúdo"
                />
              </IonCol>
            )}

            <IonCol size={icon ? "10" : "12"}>
              <div
                className={`ion-card-title ${lock ? "overlay-color" : ""}`}
                dangerouslySetInnerHTML={{
                  __html: sanitizedTitleWithoutP,
                }}
              ></div>
            </IonCol>
          </IonRow>

          {description && sanitizedContent && (
            <IonRow className="ion-description-materials">
              <IonCol size="12">
                <div
                  className={`ion-card-sub-title ${lock ? "overlay-color" : ""}`}
                  dangerouslySetInnerHTML={{
                    __html: getShortDescription(sanitizedContent),
                  }}
                ></div>
              </IonCol>
            </IonRow>
          )}
        </IonCardContent>
      </IonCard>
    </IonGrid>
  );
};

export default KindmapCard;
