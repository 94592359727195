import React, { useState, useCallback, useEffect } from "react";
import { Route, useLocation } from "react-router-dom";
import { IonContent, IonPage, IonRouterOutlet, IonToolbar, useIonRouter } from "@ionic/react";
import "./Public.css";

// Pages
import Home from "../public/Home";
import About from "../public/About";
import Team from "../public/Team";
import Investigacao from "../public/Investigacao";
import Servicos from "../public/Servicos";
import Contactos from "../public/Contactos";

interface MenuItem {
  title: string;
  path: string;
  component: React.FC;
}

const menuItems: MenuItem[] = [
  { title: "Kindmap", path: "/public/home", component: Home },
  { title: "Sobre o Projeto", path: "/public/about", component: About },
  { title: "Equipa", path: "/public/team", component: Team },
  { title: "Investigação", path: "/public/investigacao", component: Investigacao },
  { title: "Serviços de Apoio", path: "/public/servicos", component: Servicos },
  { title: "Ajuda", path: "/public/contacts", component: Contactos },
];

// Column Component
const Column: React.FC<{ index: number; menuItem: MenuItem; expandedColumn: number; handleClick: (index: number, path: string) => void; activePath: string }> = ({ index, menuItem, expandedColumn, handleClick, activePath }) => (
  <div
    className={`column ${expandedColumn === index ? "expanded" : ""} ${activePath.startsWith(menuItem.path) ? "active" : ""}`}
    onClick={() => handleClick(index, menuItem.path)}
  >
    <div className="column-p">
      <p>+</p>
      <p className="number">{menuItem.title}</p>
    </div>
    {expandedColumn === index && (
      <div className="expanded">
        <menuItem.component />
      </div>
    )}
  </div>
);

const PublicContainer: React.FC = () => {
  const router = useIonRouter();
  const location = useLocation();
  const [expandedColumn, setExpandedColumn] = useState<number>(0);

  useEffect(() => {
    const currentIndex = menuItems.findIndex((item) => location.pathname.startsWith(item.path));
    if (currentIndex !== -1) {
      setExpandedColumn(currentIndex);
    }
  }, [location.pathname]);

  const handleColumnClick = useCallback((index: number, path: string) => {
    setExpandedColumn(index);
    router.push(path, "forward");
  }, [router]);

  return (
    <IonPage>
      <IonToolbar>
        <div className="container">
          {menuItems.map((menuItem, index) => (
            <Column key={index} index={index} menuItem={menuItem} expandedColumn={expandedColumn} handleClick={handleColumnClick} activePath={location.pathname} />
          ))}
        </div>
      </IonToolbar>

      <IonContent>
        <IonRouterOutlet>
          {menuItems.map((item) => (
            <Route key={item.path} path={item.path} component={item.component} exact />
          ))}
        </IonRouterOutlet>
      </IonContent>
    </IonPage>
  );
};

export default PublicContainer;