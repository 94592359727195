import { IonCol, IonContent, IonGrid, IonHeader, IonPage, IonTitle, IonToolbar, useIonLoading, useIonRouter, useIonViewWillEnter } from "@ionic/react";
import React, { useState } from "react";
import Parse from "parse";
import './Meditations.css';

import KindmapCard from "../../components/KindmapCard";

// ICONS
import MeditationSvg from "../../components/Icons/Meditation.svg";

const Meditations: React.FC = () => {
    const [meditations, setMeditations] = useState<Parse.Object[]>([]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [userData, setUserData] = useState<Parse.Object[] | null>(null);
    const [showLoading, dismissLoading] = useIonLoading();
    const router = useIonRouter();

    useIonViewWillEnter(() => {
        const loadMeditations = async () => {
            await showLoading();
            try {
                const currentUser = Parse.User.current();
                if (!currentUser) {
                    dismissLoading();
                    router.push("/login", "root", "replace");
                    return;
                }

                const queryUserData = new Parse.Query("UserData");
                queryUserData.equalTo("userId", currentUser.id);
                queryUserData.equalTo("dataType", "CLASS");
                const userData = await queryUserData.find();

                if (userData.length > 0) {
                    setUserData(userData);

                    let allMeditations: Parse.Object[] = [];

                    for (const user of userData) {
                        if (!user.get("dataId")) continue;

                        const sessionQuery = new Parse.Query("Classes");
                        sessionQuery.equalTo("objectId", user.get("dataId"));
                        sessionQuery.include("Meditation");
                        const sessions = await sessionQuery.find();

                        if (sessions.length > 0) {
                            for (const session of sessions) {
                                const meditationRelation = session.get("Meditation");

                                if (meditationRelation instanceof Parse.Relation) {
                                    const meditationQuery = meditationRelation.query();
                                    const meditationObjects = await meditationQuery.find();

                                    const meditationsWithSessionId = meditationObjects.map(meditation => {
                                        meditation.set("sessionId", session.id);
                                        return meditation;
                                    });

                                    allMeditations = [...allMeditations, ...meditationsWithSessionId];

                                } else if (meditationRelation instanceof Parse.Object) {
                                    (meditationRelation as Parse.Object).set("sessionId", session.id);
                                    allMeditations.push(meditationRelation);
                                }
                            }
                        }
                    }

                    setMeditations(allMeditations);
                    //console.log("$$$$$$$$$$$$ MEDITATIONS: ", allMeditations);
                } else {
                    console.error("[ERROR]: Não existem dados sobre as sessões do utilizador.");
                }
            } catch (error) {
                console.error("[ERROR]: Failed to fetch meditations", error);
            } finally {
                dismissLoading();
            }
        };

        loadMeditations();
    });

    return (
        <IonPage>
            <IonHeader className="mobile-only">
                <IonToolbar>
                    <IonTitle className="ion-title-sessions">Lista de Meditações</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonGrid className="ion-padding-without-bottom">
                    {/*<div className="ion-padding-card"></div>*/}
                    {meditations.map((meditation, index) => {
                        //console.log("---> ", meditation);
                        return (
                            <IonCol size="11" key={meditation.id}>
                                <KindmapCard
                                    key={meditation?.get("id")}
                                    cardId={meditation?.get("id")}
                                    title={meditation?.get("Title")}
                                    description={""}
                                    icon={MeditationSvg}
                                    duration={meditation?.get("Duration")}
                                    url={`/app/session/${meditation?.get("sessionId")}/meditations/${meditation?.id}`}
                                    lock={false}
                                ></KindmapCard>
                            </IonCol>
                        );
                    })}
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default Meditations;