import React from "react";
import { IonContent } from "@ionic/react";
import "./Home.css";

const About: React.FC = () => {

  return (
    <IonContent className="home__container no-select">
      <div className="home__content home__content--small-width">
        <p className="home__title ion-text-center">A <strong>KindMap</strong> é um programa interativo de autoajuda, gratuito, desenvolvido com base num programa testado clinicamente e que se revelou eficaz na melhoria da saúde mental e na promoção de competências para lidar com os desafios da infertilidade.</p>

        <p className="home__title ion-text-center">A <strong>KindMap</strong> pretende promover o bem-estar emocional e a saúde psicológica de todas as pessoas que estejam a lidar com o diagnóstico e/ou com a exigência dos tratamentos de fertilidade.</p>

        <br /><br />

        <p className="home__text ion-text-justify"><strong>O que é</strong>: A <strong>KindMap</strong> é uma adaptação ao formato digital do Programa Baseado no Mindfulness para a Infertilidade (MBPI), um programa de intervenção psicológica grupal que integra práticas de mindfulness, aceitação e compaixão, e que revelou efeitos benéficos ao nível da saúde mental e ajustamento psicológico em pessoas com infertilidade. Estes efeitos mantiveram-se por um período de sete anos, independentemente de as pessoas terem vindo a ter filhos ou não.</p>

        <p className="home__text ion-text-justify"><strong>A quem se destina</strong>: A todas as pessoas com um diagnóstico de infertilidade, independentemente de se encontrarem ou não a realizar tratamentos de fertilidade, e que procurem desenvolver competências para lidar com esta circunstância na sua vida.</p>

        <p className="home__text ion-text-justify"><strong>Como funciona</strong>: O programa, desenvolvido com base nas Terapias Cognitivo-comportamentais Contextuais, é composto por 8 módulos com conteúdos dinâmicos relacionados com a infertilidade, funcionamento da mente e regulação das emoções. Em cada módulo será convidado/a a realizar exercícios experienciais e práticas meditativas, as quais é recomendado que sejam praticadas no módulo e ao longo da semana. Entre módulos, poderá facilmente aceder a todas as práticas desbloqueadas.</p>

        <p className="home__text ion-text-justify">Recomendamos que realize um módulo por semana, num total de oito semanas. No entanto, poderá guiar esta descoberta ao seu ritmo, da forma que for mais confortável e ajustada a si.</p>

        <p className="home__text ion-text-justify"><strong>Onde e quando utilizar</strong>:  Pode utilizar a <strong>KindMap</strong> onde e quando o desejar, no seu computador (na versão web) ou na aplicação para o seu tablet ou smartphone.</p>
      </div>
    </IonContent>
  );
};

export default About;