import React, { useRef, useEffect, useState } from 'react';
import { IonRow, IonCol, IonIcon, IonButton, IonButtons, useIonLoading } from '@ionic/react';
import WaveSurfer from "wavesurfer.js";
import DOMPurify from "dompurify";
import './KindmapMeditation.css';

/* ICONS */
import ButtonPlay from './Icons/botao-play.svg';
import ButtonPause from './Icons/botao-pause.svg';

interface KindmapMeditationProps {
    sessionId: string;
    meditation: Parse.Object;
    userData: Parse.Object;
}

const KindmapMeditation: React.FC<KindmapMeditationProps> = ({ meditation, userData }) => {
    const waveformRef = useRef<HTMLDivElement | null>(null);
    const wavesurfer = useRef<WaveSurfer | null>(null);
    const [playing, setPlaying] = useState(false);
    const [audioDuration, setAudioDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [showLoading, dismissLoading] = useIonLoading();

    // Sanitize the HTML content
    const rawTitle =
        meditation?.attributes?.Title === undefined
            ? "A carregar o conteúdo..."
            : meditation?.attributes?.Title || "Ups! Ocorreu um erro e não foi possível carregar o conteúdo.";
    const sanitizedTitle = DOMPurify.sanitize(rawTitle);

    useEffect(() => {
        const loadAudio = async () => {
            await showLoading();

            try {
                if (!meditation?.attributes?.Audio?.url() || !waveformRef.current) return;

                const audioUrl = meditation?.attributes?.Audio?.url();
                const abortController = new AbortController();
                const { signal } = abortController;

                if (!wavesurfer.current) {
                    wavesurfer.current = WaveSurfer.create({
                        container: waveformRef.current as HTMLElement,
                        waveColor: "#A7A6A6",
                        progressColor: "#F4CE79",
                        height: 75,
                        barWidth: 3,
                        barHeight: 1,
                        normalize: true,
                        cursorColor: "transparent",
                        autoplay: false,
                        backend: 'WebAudio',
                        fetchParams: { signal }
                    });
                }

                wavesurfer.current.load(audioUrl);

                wavesurfer.current.on("ready", () => {
                    if (!signal.aborted) {
                        setAudioDuration(wavesurfer.current?.getDuration() || 0);
                    }
                });

                wavesurfer.current.on("audioprocess", () => {
                    if (!signal.aborted) {
                        setCurrentTime(wavesurfer.current?.getCurrentTime() || 0);
                    }
                });

                wavesurfer.current.on("finish", () => {
                    setPlaying(false);
                    userData.set("completed", true);
                    userData.save();
                });

                return () => {
                    abortController.abort();
                    if (wavesurfer.current) {
                        wavesurfer.current.destroy();
                        wavesurfer.current = null;
                    }
                };
            } catch (error) {
                //console.error("[ERROR]: Failed to fetch audio file", error);
            } finally {
                await dismissLoading();
            }
        }

        loadAudio();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meditation]);

    const formatTime = (time: number) => {
        const minutes = Math.floor((time % 3600) / 60).toString();
        const seconds = Math.floor(time % 60).toString().padStart(2, '0');
        return `${minutes}:${seconds}`;
    };

    const handlePlayPause = () => {
        if (wavesurfer.current) {
            wavesurfer.current.playPause();
            setPlaying((prev) => !prev);
        }
    };

    return (
        <div className="kindmap-meditation">
            <IonRow className="ion-padding">
                <IonCol className="meditate-p">A reproduzir:</IonCol>
            </IonRow>
            <IonRow className="ion-padding">
                <IonCol className="meditate-title"><div
                    dangerouslySetInnerHTML={{
                        __html: sanitizedTitle,
                    }}
                ></div></IonCol>
            </IonRow><br />

            <IonRow className="ion-text-center">
                <IonCol>
                    <span className="timer">{formatTime(currentTime)}</span>&nbsp;
                    <span className="timer">/</span>&nbsp;
                    <span className="timer">{formatTime(audioDuration)}</span>
                </IonCol>
            </IonRow>

            <div className="waveform-container">
                <div className="waveform-container-wave" ref={waveformRef}></div>
                <div className="waveform-container-controls">
                    <IonButtons>
                        <IonButton className="button-meditation" onClick={handlePlayPause}>
                            <IonIcon
                                className="ion-icon-control ion-icon-control-play"
                                src={playing ? ButtonPause : ButtonPlay}
                            />
                        </IonButton>
                    </IonButtons>
                </div>
            </div>
        </div>
    );
};

export default KindmapMeditation;